import * as THREE from "three";
import Experience from "./Experience.js";
import { XRControllerModelFactory } from "three/examples/jsm/webxr/XRControllerModelFactory.js";

let instance = null;

export default class Controllers {
  constructor() {

    // singleton
    if(instance)
    {
        return instance;
    }
    instance = this;
    console.log('Controllers instantiated');
    
    // experience
    this.experience = new Experience();

    // miscellaneous
    this.pointer = this.experience.pointer;
    this.playbackMenu = this.experience.playbackMenu;
    this.flick = this.experience.flick;
    this.canvas = this.experience.canvas;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.renderer = this.experience.renderer;
    this.camera = this.experience.camera;
    this.intersections = this.pointer.intersections;
    this.objectsToTest = this.playbackMenu.objectsToTest;

    // controllers
    this.controller1 = this.renderer.instance.xr.getController(0);
    this.controller2 = this.renderer.instance.xr.getController(1);
    this.controller1.gamepad = undefined;
    this.controller2.gamepad = undefined;

    this.rightController = this.controller1;
    this.leftController = this.controller2;

    this.r_connection = false;
    this.l_connection = false;

    this.lastAButtonPressed = false;
    this.lastBButtonPressed = false;
    this.lastXButtonPressed = false;
    this.lastYButtonPressed = false;

    this.lastLeftTriggerPressed = false;
    this.lastRightTriggerPressed = false;

    // first controller connection listener
    this.controller1.addEventListener("connected", (event) => {
      if(event.data.handedness === 'right')
      {
        if (event.data.gamepad) {
          this.r_connection = true;
          this.controller1.gamepad = event.data.gamepad;
          this.rightController = this.controller1;
        } else {
          this.r_connection = false;

          // hide PlaybackMenu
          this.playbackMenu.hide();
        }
      }
      else if(event.data.handedness === 'left')
      {
        if (event.data.gamepad) {
          this.l_connection = true;
          this.controller1.gamepad = event.data.gamepad;
          this.leftController = this.controller1;
        } else {
          this.l_connection = false;
        }
      }
    });

    // second controller connection listener
    this.controller2.addEventListener("connected", (event) => {
      if(event.data.handedness === 'right')
      {
        if (event.data.gamepad) {
          this.r_connection = true;
          this.controller2.gamepad = event.data.gamepad;
          this.rightController = this.controller2;
        } else {
          this.r_connection = false;

          // hide PlaybackMenu
          this.playbackMenu.hide();
        }
      }
      else if(event.data.handedness === 'left')
      {
        if (event.data.gamepad) {
          this.l_connection = true;
          this.controller2.gamepad = event.data.gamepad;
          this.leftController = this.controller2;
        } else {
          this.l_connection = false;
        }
      }
    });

    this.scene.add(this.rightController);
    this.scene.add(this.leftController);

    this.controllerModelFactory = new XRControllerModelFactory();

    // first controller grip
    this.controllerGrip1 = this.renderer.instance.xr.getControllerGrip(0);
    this.controllerGrip1.add(
      this.controllerModelFactory.createControllerModel(this.controllerGrip1)
    );
    this.scene.add(this.controllerGrip1);

    // second controller grip
    this.controllerGrip2 = this.renderer.instance.xr.getControllerGrip(1);
    this.controllerGrip2.add(
      this.controllerModelFactory.createControllerModel(this.controllerGrip2)
    );
    this.scene.add(this.controllerGrip2);

    // set the pointer controller to right by default
    this.pointer.controller = this.rightController;
    this.flick.controller = this.rightController;
  }

  update() {
    // update Pointer
    this.pointer.update();

    // update Flick
    this.flick.update();
    
    // inputs
    try {
      
      // right controller
      if(this.r_connection) 
      {
        // A button
        if(this.rightController.gamepad.buttons[4].pressed !== this.lastAButtonPressed) 
        {
          if(this.rightController.gamepad.buttons[4].pressed) 
          {
            console.log("A pressed");

            // next video
            this.experience.world.nextVideo();
          } 
          else 
          {
            console.log("A released");
          }
        }
        this.lastAButtonPressed = this.rightController.gamepad.buttons[4].pressed;
        
        // B button
        if(this.rightController.gamepad.buttons[5].pressed !== this.lastBButtonPressed) 
        {
          if(this.rightController.gamepad.buttons[5].pressed)
          {
            console.log("B pressed");

            // pointer controller is right
            this.pointer.controller = this.rightController;
            this.flick.controller = this.rightController;

            // toggle PlaybackMenu
            this.playbackMenu.toggle();
          } 
          else 
          {
            console.log("B released");
          }
        }
        this.lastBButtonPressed = this.rightController.gamepad.buttons[5].pressed;
        
        // R trigger
        if(this.rightController.gamepad.buttons[0].pressed !== this.lastRightTriggerPressed)
        {
          if(this.rightController.gamepad.buttons[0].pressed) 
          {
            console.log("right trigger pressed");

            // pointer controller is right
            this.pointer.controller = this.rightController;
            this.flick.controller = this.rightController;

            // show flick indicator
            this.flick.show();

            // check for intersection with a button
            this.pointer.checkIntersections();
          } 
          else 
          {
            console.log("right trigger released");

            // hide flick indicator
            this.flick.hide();

            // call methods resulting from button press
            this.pointer.onRelease();
          }
        }
        this.lastRightTriggerPressed = this.rightController.gamepad.buttons[0].pressed;
      }

      // left controller
      if(this.l_connection)
      {
        // X button
        if(this.leftController.gamepad.buttons[4].pressed !== this.lastXButtonPressed)
        {
          if(this.leftController.gamepad.buttons[4].pressed)
          {
            console.log("X pressed");

            // reset world
            this.experience.world.reset();
          }
          else
          {
            console.log("X released");
          }
        }
        this.lastXButtonPressed = this.leftController.gamepad.buttons[4].pressed;
        
        // Y button
        if(this.leftController.gamepad.buttons[5].pressed !== this.lastYButtonPressed)
        {
          if(this.leftController.gamepad.buttons[5].pressed)
          {
            console.log("Y pressed");

            // pointer controller is left
            this.pointer.controller = this.leftController;
            this.flick.controller = this.leftController;

            // toggle PlaybackMenu
            this.playbackMenu.toggle();
          }
          else
          {
            console.log("Y released");
          }
        }
        this.lastYButtonPressed = this.leftController.gamepad.buttons[5].pressed;
        
        // left trigger
        if(this.leftController.gamepad.buttons[0].pressed !== this.lastLeftTriggerPressed)
        {
          if(this.leftController.gamepad.buttons[0].pressed)
          {
            console.log("left trigger pressed");

            // pointer controller is left
            this.pointer.controller = this.leftController;
            this.flick.controller = this.leftController;

            // show flick indicator
            this.flick.show();

            // check for intersection with a button
            this.pointer.checkIntersections();
          }
          else
          {
            console.log("left trigger released");

            // hide flick indicator
            this.flick.hide();

            // call methods resulting from button press
            this.pointer.onRelease();
          }
        }
        this.lastLeftTriggerPressed = this.leftController.gamepad.buttons[0].pressed;
      }

    // log errors
    } 
    catch (error)
    {
      console.log(error);
    }
  }
}
