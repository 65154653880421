import * as THREE from 'three'
import Experience from "../../Experience.js";
import { TextGeometry } from 'three/examples/jsm/Addons.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';

let instance = null; 

export default class PlaybackMenu
{
    constructor()
    {
        // singleton
        if(instance)
        {
            return instance;
        }
        instance = this;
        console.log('PlaybackMenu instantiated');

        // experience
        this.experience = new Experience();

        // miscellaneous
        this.group = new THREE.Group();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.objectsToTest = [];
        this.hapticObjects = [];
        this.highlightObjects = [];
        
        /**
         * menu materials
         */
        // loader
        const textureLoader = new THREE.TextureLoader();
        
        // plane
        const playbackTexture = textureLoader.load('../textures/plane.png');
        
        this.playbackMaterial = new THREE.MeshStandardMaterial({map: playbackTexture, transparent: true});

        // volume
        const volumeTexture = textureLoader.load('../textures/volume.png');
        const volumeHighlightedTexture = textureLoader.load('../textures/volume_highlighted.png');
        const volumeMutedTexture = textureLoader.load('../textures/volume_muted.png');
        const volumeHighlightedMutedTexture = textureLoader.load('../textures/volume_highlighted_muted.png');

        this.volumeMaterial = new THREE.MeshStandardMaterial({ map: volumeTexture, transparent: true});
        this.volumeHighlightedMaterial = new THREE.MeshStandardMaterial({ map: volumeHighlightedTexture, transparent: true});
        this.volumeMutedMaterial = new THREE.MeshStandardMaterial({ map: volumeMutedTexture, transparent: true });
        this.volumeHighlightedMutedMaterial = new THREE.MeshStandardMaterial({ map: volumeHighlightedMutedTexture, transparent: true });

        // play pause
        const playTexture = textureLoader.load('../textures/play.png');
        const playHighlightedTexture = textureLoader.load('../textures/play_highlighted.png');
        const pauseTexture = textureLoader.load('../textures/pause.png');
        const pauseHighlightedTexture = textureLoader.load('../textures/pause_highlighted.png');
        
        this.playMaterial = new THREE.MeshStandardMaterial({ map: playTexture, transparent: true });
        this.playHighlightedMaterial = new THREE.MeshStandardMaterial({ map: playHighlightedTexture, transparent: true });
        this.pauseMaterial = new THREE.MeshStandardMaterial({ map: pauseTexture, transparent: true });
        this.pauseHighlightedMaterial = new THREE.MeshStandardMaterial({ map: pauseHighlightedTexture, transparent: true });
        
        // knob
        const knobTexture = textureLoader.load('../textures/knob.png');
        const knobHighlightedTexture = textureLoader.load('../textures/knob_highlighted.png');

        this.knobMaterial = new THREE.MeshStandardMaterial({ map: knobTexture, transparent: true });
        this.knobHighlightedMaterial = new THREE.MeshStandardMaterial({ map: knobHighlightedTexture, transparent: true });

        // settings
        const settingsTexture = textureLoader.load('../textures/settings.png');
        const settingsHighlightedTexture = textureLoader.load('../textures/settings_highlighted.png');

        this.settingsMaterial = new THREE.MeshStandardMaterial({ map: settingsTexture, transparent: true});
        this.settingsHighlightedMaterial = new THREE.MeshStandardMaterial({ map: settingsHighlightedTexture, transparent: true});

        // tutorial
        const tutorialTexture = textureLoader.load('../textures/tutorial.png');
        const tutorialHighlightedTexture = textureLoader.load('../textures/tutorial_highlighted.png');

        this.tutorialMaterial = new THREE.MeshStandardMaterial({ map: tutorialTexture, transparent: true});
        this.tutorialHighlightedMaterial = new THREE.MeshStandardMaterial({ map: tutorialHighlightedTexture, transparent: true});

        // sign-in
        const signInTexture = textureLoader.load('../textures/sign_in.png');
        const signInHighlightedTexture = textureLoader.load('../textures/sign_in_highlighted.png');

        this.signInMaterial = new THREE.MeshStandardMaterial({ map: signInTexture, transparent: true});
        this.signInHighlightedMaterial = new THREE.MeshStandardMaterial({ map: signInHighlightedTexture, transparent: true});

        // exit
        const exitTexture = textureLoader.load('../textures/exit.png');
        const exitHighlightedTexture = textureLoader.load('../textures/exit_highlighted.png');

        this.exitMaterial = new THREE.MeshStandardMaterial({ map: exitTexture, transparent: true});
        this.exitHighlightedMaterial = new THREE.MeshStandardMaterial({ map: exitHighlightedTexture, transparent: true});

        /**
         * menu meshes
         */

        // menu config
        this.menuDistance = 0.9;
        this.menuHeight = -0.45;
        this.menuScale = 0.8;

        this.buttonScale = 0.053;
        this.buttonZ = 0.001;
        this.buttonY = 0.0375;
        this.buttonX = 0.16;
        this.buttonStep = 0.07;

        this.knobScale = 0.023;
        
        // debug
        // const finalTexture = textureLoader.load('../textures/final.png');
        // this.finalMaterial = new THREE.MeshStandardMaterial({ map: finalTexture, transparent: true});
        // this.final = new THREE.Mesh(
        //     new THREE.PlaneGeometry(1, 0.2295),
        //     this.finalMaterial
        // );
        // this.final.scale.set(this.menuScale, this.menuScale, this.menuScale);
        // this.final.translateY(0.2);
        // this.group.add(this.final);

        // plane 
        this.plane = new THREE.Mesh(
            new THREE.PlaneGeometry(1, 0.2295),
            this.playbackMaterial
        );
        this.plane.scale.set(this.menuScale, this.menuScale, this.menuScale);
        this.group.add(this.plane);
        this.objectsToTest.push(this.plane);

        // volume
        this.volume = new THREE.Mesh(
            new THREE.PlaneGeometry(this.buttonScale * this.menuScale, this.buttonScale * this.menuScale),
            this.volumeMaterial
        );
        this.volume.renderOrder = 1;
        this.volume.position.z = this.buttonZ;
        this.volume.translateY(this.buttonY);
        this.volume.translateX(-0.337);
        this.group.add(this.volume);
        this.objectsToTest.push(this.volume);
        this.hapticObjects.push(this.volume);

        this.volume.highlight = this.volumeHighlightedMaterial;
        this.volume.unhighlight = this.volumeMaterial;

        // volume knob
        this.volumeKnob = new THREE.Mesh(
            new THREE.PlaneGeometry(this.knobScale * this.menuScale, this.knobScale * this.menuScale),
            this.knobMaterial
        );
        this.volumeKnob.renderOrder = 1;
        this.volumeKnob.position.z = this.buttonZ;
        this.volumeKnob.translateY(this.buttonY);
        this.volumeKnob.translateX(-0.24);
        this.group.add(this.volumeKnob);
        this.objectsToTest.push(this.volumeKnob);
        this.hapticObjects.push(this.volumeKnob);

        this.volumeKnob.highlight = this.knobHighlightedMaterial;
        this.volumeKnob.unhighlight = this.knobMaterial;

        // play pause
        this.playPause = new THREE.Mesh(
            new THREE.PlaneGeometry(this.buttonScale * this.menuScale, this.buttonScale * this.menuScale),
            this.pauseMaterial
        );
        this.playPause.renderOrder = 1;
        this.playPause.position.z = this.buttonZ;
        this.playPause.translateY(this.buttonY);
        this.group.add(this.playPause);
        this.objectsToTest.push(this.playPause);
        this.hapticObjects.push(this.playPause);

        this.playPause.highlight = this.pauseHighlightedMaterial;
        this.playPause.unhighlight = this.pauseMaterial;

        // playback knob
        this.playbackKnob = new THREE.Mesh(
            new THREE.PlaneGeometry(this.knobScale * this.menuScale, this.knobScale * this.menuScale),
            this.knobMaterial
        );
        this.playbackKnob.renderOrder = 1;
        this.playbackKnob.position.z = this.buttonZ;
        this.playbackKnob.translateY(-0.0565);
        this.playbackKnob.translateX(-0.258);
        this.group.add(this.playbackKnob);
        this.objectsToTest.push(this.playbackKnob);
        this.hapticObjects.push(this.playbackKnob);

        this.playbackKnob.highlight = this.knobHighlightedMaterial;
        this.playbackKnob.unhighlight = this.knobMaterial;

        // current time
        const fontLoader = new FontLoader();
        fontLoader.load('../textures/font.json', (font) => { 
            var currentTimeGeometry = new TextGeometry('0:00', {
                    size: 0.012,
                    height: 0.001,
                    curveSegments: 6,
                    font: font,
            });
            var color = new THREE.Color({ r: 255, g: 255, b: 255});
            var textMaterial = new THREE.MeshBasicMaterial({ color: color });
            this.currentTime = new THREE.Mesh(currentTimeGeometry , textMaterial);
            this.group.add(this.currentTime);
            this.currentTime.renderOrder = 1;
            this.currentTime.position.z = this.buttonZ;
            this.currentTime.translateY(-0.0565 + 0.015);
            this.currentTime.translateX(-0.258 - 0.015);
            this.group.add(this.currentTime);
        })

        // video length
        fontLoader.load('../textures/font.json', (font) => { 
            var videoLengthGeometry = new TextGeometry('1:12', {
                    size: 0.012,
                    height: 0.001,
                    curveSegments: 6,
                    font: font,
            });
            var color = new THREE.Color({ r: 255, g: 255, b: 255});
            var textMaterial = new THREE.MeshBasicMaterial({ color: color });
            this.videoLength = new THREE.Mesh(videoLengthGeometry , textMaterial);
            this.group.add(this.videoLength);
            this.videoLength.renderOrder = 1;
            this.videoLength.position.z = this.buttonZ;
            this.videoLength.translateY(-0.0565 + 0.015);
            this.videoLength.translateX(0.258);
            this.group.add(this.videoLength);
        })

        // video length 

        // settings
        this.settings = new THREE.Mesh(
            new THREE.PlaneGeometry(this.buttonScale * this.menuScale, this.buttonScale * this.menuScale),
            this.settingsMaterial
        );
        this.settings.renderOrder = 1;
        this.settings.position.z = this.buttonZ;
        this.settings.translateY(this.buttonY);
        this.settings.translateX(this.buttonX);
        // this.group.add(this.settings);
        this.objectsToTest.push(this.settings);
        this.hapticObjects.push(this.settings);

        this.settings.highlight = this.settingsHighlightedMaterial;
        this.settings.unhighlight = this.settingsMaterial;

        // tutorial
        this.tutorial = new THREE.Mesh(
            new THREE.PlaneGeometry(this.buttonScale * this.menuScale, this.buttonScale * this.menuScale),
            this.tutorialMaterial
        );
        this.tutorial.renderOrder = 1;
        this.tutorial.position.z = this.buttonZ;
        this.tutorial.translateY(this.buttonY);
        this.tutorial.translateX(this.buttonX + this.buttonStep);
        // this.group.add(this.tutorial);
        this.objectsToTest.push(this.tutorial);
        this.hapticObjects.push(this.tutorial);

        this.tutorial.highlight = this.tutorialHighlightedMaterial;
        this.tutorial.unhighlight = this.tutorialMaterial;
        
        // sign-in
        this.signIn = new THREE.Mesh(
            new THREE.PlaneGeometry(1.8333 * this.buttonScale * this.menuScale, this.buttonScale * this.menuScale),
            this.signInMaterial
        );
        this.signIn.renderOrder = 1;
        this.signIn.position.z = this.buttonZ;
        this.signIn.translateY(this.buttonY);
        this.signIn.translateX(this.buttonX + this.buttonStep + 1.8333 * this.buttonStep * 0.7);
        this.group.add(this.signIn);
        this.objectsToTest.push(this.signIn);
        this.hapticObjects.push(this.signIn);

        this.signIn.highlight = this.signInHighlightedMaterial;
        this.signIn.unhighlight = this.signInMaterial;

        // exit
        this.exit = new THREE.Mesh(
            new THREE.PlaneGeometry(this.buttonScale * this.menuScale, this.buttonScale * this.menuScale),
            this.exitMaterial
        );
        this.exit.renderOrder = 1;
        this.exit.position.z = this.buttonZ;
        this.exit.translateY(-0.045);
        this.exit.translateX(0.185 + this.buttonStep * 2);
        this.group.add(this.exit);
        this.objectsToTest.push(this.exit);
        this.hapticObjects.push(this.exit);

        this.exit.highlight = this.exitHighlightedMaterial;
        this.exit.unhighlight = this.exitMaterial;

        // populate and remove plane from highlightObjects
        this.highlightObjects = Array.from(this.objectsToTest);
        this.highlightObjects.shift();
        
        // group visibility
        this.group.visible = false;
        this.scene.add(this.group);
    }

    update()
    {
    }

    changeHighlight(mesh)
    {
        if(mesh.material === mesh.highlight)
        {
            mesh.material = mesh.unhighlight;
        }
        else
        {
            mesh.material = mesh.highlight;
        }
    }

    muteOrUnmute(muted)
    {
        // change mesh material to mute or unmute
        if(muted)
        {
            this.volume.material = this.volumeHighlightedMutedMaterial;
            this.volume.highlight = this.volumeHighlightedMutedMaterial;
            this.volume.unhighlight = this.volumeMutedMaterial;
        }
        else
        {
            this.volume.material = this.volumeHighlightedMaterial;
            this.volume.highlight = this.volumeHighlightedMaterial;
            this.volume.unhighlight = this.volumeMaterial;
        }
    }

    playOrPause(paused)
    {
        // change mesh material to play or pause
        if(paused)
        {
            this.playPause.material = this.pauseHighlightedMaterial;
            this.playPause.highlight = this.pauseHighlightedMaterial;
            this.playPause.unhighlight = this.pauseMaterial;
        }
        else
        {
            this.playPause.material = this.playHighlightedMaterial;
            this.playPause.highlight = this.playHighlightedMaterial;
            this.playPause.unhighlight = this.playMaterial;
        }
    }

    toggle()
    {
        if(this.group.visible)
        {
            // hide
            this.hide();
        }
        else
        {
            // show
            this.show();
        }
    }

    show()
    {
        if(!this.group.visible)
        {
            // changes PlaybackMenu position and orientation to be ergonomic
            this.group.position.copy(this.camera.instance.position);
            this.group.rotation.copy(this.camera.instance.rotation);
            this.group.updateMatrix();
            this.group.translateZ(-this.menuDistance);
            this.group.position.y = this.camera.instance.position.y + this.menuHeight;
            this.group.lookAt(this.camera.instance.position);

            // toggle visible
            this.group.visible = true;
        }
    }

    hide()
    {
        this.group.visible = false;
    }
}