export const menuDistance = 0.9;
export const menuHeight = -0.45;
export const menuScale = 0.8;

export const buttonScale = 0.053;
export const buttonZ = 0.001;
export const buttonY = 0.0375;
export const buttonX = 0.16;
export const buttonStep = 0.07;

export const knobScale = 0.023;

export const barScale = 0.0053;
export const barY = 0.0367;
export const playbackBarLength = 100;
export const volumeBarLength = 24.6305;