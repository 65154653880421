import * as THREE from 'three'
import Experience from '../Experience.js'

let instance = null;

export default class Pointer
{
    constructor()
    {
        // singleton
        if(instance)
        {
            return instance;
        }
        instance = this;
        console.log('Pointer instantiated');

        // experience
        this.experience = new Experience();

        // miscellaneous
        this.group = new THREE.Group();
        this.videoPlayer = this.experience.world.videoPlayer;
        this.playbackMenu = this.experience.playbackMenu;
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.renderer = this.experience.renderer;
        this.objectsToTest = this.playbackMenu.objectsToTest;
        this.hapticObjects = this.playbackMenu.hapticObjects;
        this.highlightObjects = this.playbackMenu.highlightObjects;
        this.intersections = [];
        this.objectClicked = null;
        this.lastIntersected = null;
        this.visible = false;
        this.controller = undefined;

        // raycaster
        this.raycaster = new THREE.Raycaster();

        // ray mesh
        this.rayLength = 1;
        this.rayRadius = 0.005;
        this.ray = new THREE.Mesh(
            new THREE.ConeGeometry(this.rayRadius,this.rayLength), 
            new THREE.MeshStandardMaterial()
        );
        this.ray.rotation.x = -Math.PI / 2;
        this.ray.visible = false;
        this.group.add(this.ray);

        // // debug
        // this.debug = new THREE.Mesh(
        //     new THREE.BoxGeometry(0.1, 0.1, 0.1),
        //     new THREE.MeshStandardMaterial()
        // )
        // this.playbackMenu.group.add(this.debug)

        // group visibility
        this.group.visible = false
        this.scene.add(this.group)
    }

    update()
    {
        if(this.playbackMenu.group.visible)
        {
            if(!(this.controller.gamepad === undefined))
            {
                // orient the ray mesh for translation
                this.group.position.copy(this.controller.position);
                this.group.rotation.copy(this.controller.rotation);
                this.group.updateMatrix();
                this.ray.visible = true;

                // scale and position the ray mesh
                const distance = this.group.position.distanceTo(this.playbackMenu.group.position);
                if(distance < this.rayLength)
                {
                    this.ray.scale.y = distance / this.rayLength;
                    this.group.translateZ(-distance / this.rayLength / 2);
                }
                else
                {
                    this.group.translateZ(-this.rayLength / 2);
                }

                this.intersections = this.raycaster.intersectObjects(this.playbackMenu.group);
                if (this.intersections.length)
                {
                    this.playbackMenu.playbackKnob.position.x = 0
                    // this.debug.position.copy(this.intersections[0].point)
                    this.playbackMenu.playbackKnob.translateX(this.intersections[0].point.x)
                    this.playbackMenu.playbackKnob.updateMatrix()
                    this.playbackMenu.playbackKnob.translateX(0)
                }

                // position the raycaster
                this.raycaster.setFromXRController(this.controller)

                // update intersections
                this.intersections = this.raycaster.intersectObjects(this.highlightObjects);

                // haptic bump if new intersection
                if(this.intersections.length && 
                    this.lastIntersected !== this.intersections[0].object)
                    {
                        // highlight
                        this.playbackMenu.changeHighlight(this.intersections[0].object);
                        
                        // pulse
                        if(this.hapticObjects.includes(this.intersections[0].object))
                        {
                            this.controller.gamepad.hapticActuators[0].pulse(0.5, 100);
                            this.lastIntersected = this.intersections[0].object;
                        }
                    }
                else if(this.intersections.length === 0)
                {
                    if(this.lastIntersected !== null)
                    {
                        this.playbackMenu.changeHighlight(this.lastIntersected);
                    }
                    this.lastIntersected = null;
                }
            }
            // show after updating
            this.group.visible = true;
        }
        else
        {
            // hide
            this.group.visible = false;
        }
    }

    checkIntersections()
    {
        if(this.playbackMenu.group.visible)
        {
            // position the raycaster in case of controller switch after last update
            this.raycaster.setFromXRController(this.controller);

            // update intersections
            this.intersections = this.raycaster.intersectObjects(this.objectsToTest);
            if(this.intersections.length === 0)
            {
                this.objectClicked = null;
            }
            else
            {
                // this.debug.position.x = 0
                // this.debug.translateX(this.intersections[0].point.x)
                // this.debug.updateMatrix()
                // this.debug.translateX(0)
                this.objectClicked = this.intersections[0].object;
            }
        }
        else
        {
            this.objectClicked = null;
        }
    }

    onRelease() 
    {
        if(this.objectClicked === this.playbackMenu.volume)
        {
            // mute or unmute
            const muted = this.videoPlayer.muteOrUnmuteVideo();

            // change button material
            this.playbackMenu.muteOrUnmute(muted);
        }
        else if(this.objectClicked === this.playbackMenu.playPause) 
        {
            // play or pause video
            const paused = this.videoPlayer.playOrPauseVideo();

            // change button mesh
            this.playbackMenu.playOrPause(paused);
        }
        else if(this.objectClicked === this.playbackMenu.exit) 
        {
            // exit to butterflies
            this.experience.world.reset();
        }
        else if(this.objectClicked === this.playbackMenu.playbackKnob)
        {
            console.log(this.intersections)
            // orient the ray mesh for translation
            // this.playbackMenu.playbackKnob.position.x = 0
            // this.playbackMenu.playbackKnob.translateX(this.intersections[0].point.x)
            // this.playbackMenu.playbackKnob.updateMatrix()
            // this.playbackMenu.playbackKnob.translateX(0)
            // this.intersections = this.raycaster.intersectObjects(this.objectsToTest);
            // this.playbackMenu.playbackKnob.position.x = -this.intersections[0].point.x + this.group.position.x;
            // this.playbackMenu.playbackKnob.rotation.copy(this.controller.rotation);
            // this.playbackMenu.playbackKnob.updateMatrix();
            // this.playbackMenu.playbackKnob.translateZ(-0.1);
            // this.playbackMenu.playbackKnob.position.copy(this.intersections[0].point)
            // console.log(this.intersections)
        }
        this.objectClicked = null;
    }
}