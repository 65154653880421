import * as THREE from 'three';
import Experience from '../../Experience.js';
import * as config from './Config.js';

let instance = null; 

export default class PlaybackBar
{
    constructor()
    {
        // singleton
        if(instance)
        {
            return instance;
        }
        instance = this;
        console.log('VolumeBar instantiated');

        // experience
        this.experience = new Experience();

        // miscellaneous
        this.group = new THREE.Group();

        // loader
        const textureLoader = new THREE.TextureLoader();
        
        // bar mesh
        this.barTexture = textureLoader.load('../textures/progress_bar.png');
        this.barMaterial = new THREE.MeshStandardMaterial({map: this.barTexture, transparent: true}); 
        this.mesh = new THREE.Mesh(
            new THREE.PlaneGeometry(config.barScale, config.barScale),
            this.barMaterial
        );
        this.mesh.renderOrder = 0;
        this.mesh.position.z = config.buttonZ;
        this.mesh.translateY(-0.0565);
        this.mesh.translateX(-0.258);
        this.group.add(this.mesh);
        this.experience.playbackMenu.group.add(this.group);
    }

    update(force = false)
    {
        if (force || this.experience.world.videoPlayer.video.currentTime) {
            // update bar
            let progress = this.experience.world.videoPlayer.video.currentTime / 
                this.experience.world.videoPlayer.video.duration * config.playbackBarLength;
            this.mesh.scale.x = progress;
            this.group.position.x = (progress / 2) * config.barScale;

            // update knob
            this.experience.playbackMenu.playbackKnob.position.x = -0.258 + progress * config.barScale;

            // update current time text
            this.experience.playbackMenu.currentTime.position.x = -0.258 - 0.015 + progress * config.barScale;
        }
    }
}