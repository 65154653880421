import * as THREE from "three";
import Sizes from "./Utils/Sizes.js";
import Resources from "./Utils/Resources.js";
import Camera from "./Camera.js";
import Renderer from "./Renderer.js";
import World from "./World/World.js";
import sources from "./sources.js";
import { VRButton } from "three/examples/jsm/webxr/VRButton.js";
import Controllers from "./Controllers.js";
import Pointer from "./World/Pointer.js"
import PlaybackMenu from "./World/PlaybackMenu/PlaybackMenu.js";
import Flick from "./World/Flick.js";
import VolumeBar from "./World/PlaybackMenu/VolumeBar.js";
import PlaybackBar from "./World/PlaybackMenu/PlaybackBar.js";

let instance = null;

export default class Experience {
  constructor(canvas) {
    
    // singleton
    if (instance) {
      return instance;
    }
    instance = this;
    console.log('Experience instantiated');
    
    // global access
    window.experience = this;

    // miscellaneous
    this.canvas = canvas;
    this.scene = new THREE.Scene();
    this.clock = new THREE.Clock();
    this.clock.start();

    // custom class instantiation
    this.sizes = new Sizes();
    this.resources = new Resources(sources);
    this.camera = new Camera();
    this.renderer = new Renderer();
    this.world = new World();
    this.playbackMenu = new PlaybackMenu();
    this.volumeBar = new VolumeBar();
    this.playbackBar = new PlaybackBar();
    this.pointer = new Pointer(); 
    this.flick = new Flick();
    this.controllers = new Controllers();

    // VRButton
    this.renderer.instance.xr.enabled = true;
    document.body.appendChild(VRButton.createButton(this.renderer.instance));

    // tick
    this.renderer.instance.setAnimationLoop(() => {
      this.world.update();
      this.playbackMenu.update();
      this.volumeBar.update();
      this.playbackBar.update();
      this.controllers.update();
      this.renderer.update();
    });

    // mousemove listener
    this.mouse = new THREE.Vector2();
    this.INTERSECTED = null;
    window.addEventListener("mousemove", (event) => {
      this.mouse.x = (event.clientX / this.sizes.width) * 2 - 1;
      this.mouse.y = -(event.clientY / this.sizes.height) * 2 + 1;
    });

    // resize listener
    this.sizes.on("resize", () => {
      this.resize();
      this.camera.resize();
      this.renderer.resize();
    });
  }

  resize() {
    console.log("resize occured");
    this.camera.resize();
  }
}
