import * as THREE from 'three'
import Experience from '../Experience.js'
import flickRingVertexShader from "../../shaders/FlickRing/vertex.glsl";
import flickRingFragmentShader from "../../shaders/FlickRing/fragment.glsl";

export default class Flick
{
    constructor()
    {
        // experience
        this.experience = new Experience();

        // miscellaneous
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.renderer = this.experience.renderer;
        this.camera = this.experience.camera;
        this.controller = undefined;
        this.visible = false;

        // flick ring
        this.flickRing = new THREE.Mesh(
        new THREE.PlaneGeometry(0.25, 0.25, 1, 1),
        new THREE.ShaderMaterial({
            vertexShader: flickRingVertexShader,
            fragmentShader: flickRingFragmentShader,
            transparent: true,
            side: THREE.DoubleSide,
            uniforms: 
            {
                uTime: { value: 0 },
                flickDistance: { value: 0 },
            },
        }));

        // flick config
        this.flickThreshold = 0.2; // temp for debug
        this.flickableOrigin = new THREE.Vector3();
        this.flickRing.renderOrder = 2;

        // flick group
        this.group = new THREE.Group();
        this.group.add(this.flickRing);
        this.group.visible = false;
        this.scene.add(this.group);
    }

    update()
    {
        if(this.visible) 
        {
            if(!(this.controller.gamepad === undefined))
            {
                // orient
                this.group.position.copy(this.controller.position);
                this.group.lookAt(this.camera.instance.position);

                // flick mechanic
                this.flick();

                // show
                this.group.visible = true;
            }
        }
        else
        {
            // hide
            this.group.visible = false;
        }
    }

    flick()
    {
        this.flickDistance = this.controller.position.distanceTo(this.flickableOrigin) > 0.1;
        if (this.flickDistance > this.flickThreshold) 
        {
            console.log("flick");

            // haptic pulse
            this.controller.gamepad.hapticActuators[0].pulse(0.5, 100);
            
            // next video
            this.experience.world.nextVideo();

            // force update playbackBar
            this.experience.playbackBar.update(true);

            // hide flick indicator
            this.hide();
        } 
        else 
        {
            this.group.position.copy(this.controller.position);
            this.group.lookAt(this.camera.instance.position);
            this.flickRing.material.uniforms.flickDistance.value = this.flickDistance / this.flickThreshold;
        }
    }

    show() 
    {
        // show
        this.visible = true;
        if(!(this.controller.gamepad === undefined))
        {
            this.flickableOrigin.copy(this.controller.position);
        }
    }
      
    hide() 
    {
        // hide
        this.visible = false;
    }
}