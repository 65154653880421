const feed = [
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/adf605c144640837503a23b2e3c72365/manifest/video.m3u8",
    creator: "CreatorName2",
    title: "enetering the water",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/0e9901c4e3299603ee1a0b35ef7a6c40/manifest/video.m3u8",
    creator: "CreatorName2",
    title: "calm waters",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/74f230ceafd7f83a2a187773769aad6d/manifest/video.m3u8",
    creator: "CreatorName2",
    title: "paddleboarder",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/382a3e8d972e288a43b78865ec611ec1/manifest/video.m3u8",
    creator: "CreatorName2",
    title: "big wave",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/30899829cbf4b90f5b77495048378a30/manifest/video.m3u8",
    creator: "CreatorName2",
    title: "friendly surfer",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/92c449f46952244312a8cdd4c7eb81f3/manifest/video.m3u8",
    creator: "CreatorName2",
    title: "exiting the water",
  },

  // Add more video objects here as needed
];

export default feed;
