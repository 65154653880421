import * as THREE from "three";
import Experience from "../Experience.js";
import feed from "../../feed.js";
let instance = null

export default class VideoPlayer {
  constructor() {

    // singleton
    if(instance)
    {
      return instance;
    }
    instance = this;
    console.log('VideoPlayer instantiated')

    // experience
    this.experience = new Experience();

    // miscellaneous
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.group = new THREE.Group();

    // videoSphere
    this.video = document.getElementById("video");
    this.videoTexture = new THREE.VideoTexture(this.video);
    this.videoMaterial = new THREE.MeshBasicMaterial({
      map: this.videoTexture,
    });
    this.videoSphereGeoemetry = new THREE.SphereGeometry(9, 60, 40);
    this.videoSphereGeoemetry.scale(-0.3, 0.3, 0.3);
    this.videoSphereGeoemetry.translate(0, 1.7, 0);
    this.videoSphere = new THREE.Mesh(
      this.videoSphereGeoemetry,
      this.videoMaterial
    );

    // feed
    this.feed = feed;
    this.currentFeedIndex = 0;
    
    // log each video url in feed
    this.feed.forEach((video) => {
      console.log(video.streamURL);
    });

    // add videoSphere to videoPlayer
    this.group.add(this.videoSphere);
    this.group.visible = false;
    this.scene.add(this.group);

    // playback menu
    this.pausedByUser = false;
    this.mutedByUser = false;
  }

  playVideo(index) {
    let hlsManifestURL = this.feed[index].hlsManifestURL + "?clientBandwidthHint=10";
    
    if (Hls.isSupported()) {
      var hls = new Hls({ enableWorker: false });
      window.hls = hls;
      console.log(hlsManifestURL);
      try {
        hls.loadSource(hlsManifestURL);
        hls.attachMedia(this.video);
      } catch (error) {
        console.log(error);
      }
    } else if (this.video.canPlayType("application/vnd.apple.mpegurl")) {
      this.video.src = hlsManifestURL;
    }
    if(!this.pausedByUser)
    {
      this.video.play();
    }
    if(!this.mutedByUser)
    {
      this.video.muted = false;
    }
    this.group.visible = true;
  }
  
  nextVideo() {
    // increment currentFeedIndex but loop back to 0 if at end of feed
    this.currentFeedIndex = (this.currentFeedIndex + 1) % this.feed.length;
    this.video.pause();
    this.video.currentTime = 0;

    // wait 50 ms to play next video
    setTimeout(() => {
      this.playVideo(this.currentFeedIndex);
    }, 100);

    console.log("playing next video");
  }

  playFirstVideo() {
    this.currentFeedIndex = 0;
    this.playVideo(this.currentFeedIndex);
  }

  previousVideo() {}

  hide() {
    this.currentFeedIndex = 0;
    this.group.visible = false;
    this.video.pause();
    this.video.currentTime = 0;
  }

  muteOrUnmuteVideo()
  {
    if(this.mutedByUser)
    {
      this.video.muted = false;
      this.mutedByUser = false;
    }
    else
    {
      this.video.muted = true;
      this.mutedByUser = true;
    }
    return(this.video.muted)
  }

  playOrPauseVideo()
  {
    // inside videosphere scene
    if(this.group.visible)
    {
      if(this.pausedByUser)
      {
        setTimeout(() => {
          this.video.play();
          this.pausedByUser = false;
        }, 50);
      }
      else
      {
        setTimeout(() => {
          this.video.pause();
          this.pausedByUser = true;
        }, 50);
      }
      return(this.video.paused)
    }

    // inside butterflies scene
    else
    {
      if(this.pausedByUser)
      {
        this.pausedByUser = false;
      }
      else
      {
        this.pausedByUser = true;
      }
      return(!this.pausedByUser)
    }
  }
  
  update() {}
}
